import { gsap } from "../plugins/gsap";
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger, ScrollTrigger);

const classes = {
  active: "active",
  hidden: "hidden",
  gradient: "text-blue-aqua",
};

const selectors = {
  controls: "[data-location-control]",
  locations: "[data-location]",
  lists: "[data-list]",
  btns: "[data-btn-category]",
  headlineOne: "#headlineOne",
  headlineTwo: "#headlineTwo",
  headlineTwoGradient: "#headlineTwoGradient",
  subtitle: "#subtitle",
  cloudTop: "#cloudTop",
  cloudBottom: "#cloudBottom",
  selectByLocation: "#selectByLocation",
  bgImg: "#bgImg",
  locationPins: ".stampli-locations__item",
  btnPositionsByLocation: "[data-positions-by-location]",
  locationsContainer: "#stampliLocations",
  perks: ".stampli-perks__item",
};

class Careers {
  constructor() {
    this.controls = document.querySelectorAll(selectors.controls);
    this.locations = document.querySelectorAll(selectors.locations);
    this.select = document.querySelector(selectors.selectByLocation);
    this.btns = document.querySelectorAll(selectors.btns);
    this.positionsBtns = document.querySelectorAll(
      selectors.btnPositionsByLocation
    );
    this.events();
  }

  events() {
    this.btns.forEach((item) =>
      item.addEventListener("click", this.handleList.bind(this, item))
    );

    this.positionsBtns.forEach((item) =>
      item.addEventListener("click", this.handleLocation.bind(this, item))
    );

    this.controls.forEach((item) =>
      item.addEventListener(
        "click",
        this.selectControl.bind(this, item.dataset.control)
      )
    );

    this.select.addEventListener("change", (e) =>
      this.selectLocation(e.target.value)
    );
  }

  handleLocation(item) {
    if (!item) {
      return;
    }

    this.selectLocation(item.dataset.control);
    this.selectControl(item.dataset.control);
    this.select.value = item.dataset.control;
  }

  handleList(item) {
    if (!item) {
      return;
    }

    item.classList.toggle(classes.active);
    item.nextElementSibling.classList.toggle(classes.active);
  }

  selectLocation(id) {
    if (!this.locations) {
      return;
    }

    const selected = Array.from(this.locations).filter(
      (item) => item.dataset.locationOrder == id
    );
    this.locations.forEach((item) => {
      item.classList.add(classes.hidden);
    });

    selected[0].classList.remove(classes.hidden);
  }

  selectControl(index) {
    if (!index) {
      return;
    }

    // this.controls[index];

    this.controls.forEach((item) => {
      if (item.dataset.control == index) {
        item.classList.add(classes.active);
      } else {
        item.classList.remove(classes.active);
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".page-template-careers-page")) {
    new Careers();

    const scrollToJobListing = document.querySelectorAll(
      selectors.btnPositionsByLocation
    );

    if (scrollToJobListing) {
      scrollToJobListing.forEach((item) => {
        item.addEventListener("click", () => {
          document.querySelector("#jobPositionsByLocation").scrollIntoView({
            behavior: "smooth",
          });
        });
      });
    }

    $(".careers-news-carousel--news").slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-left w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-left text-blue-navy group-hover:text-white'></span><span class='sr-only'>Prev</span></button>",
      nextArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-right w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-right text-blue-navy group-hover:text-white'></span><span class='sr-only'>Next</span></button>",
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".careers-news-carousel--videos").slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-left w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-left text-blue-navy group-hover:text-white'></span><span class='sr-only'>Prev</span></button>",
      nextArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-right w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-right text-blue-navy group-hover:text-white'></span><span class='sr-only'>Next</span></button>",
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    const tl = gsap.timeline({
      delay: 1.5,
      defaults: { duration: 0.7, ease: "power3.out" },
      paused: false,
    });

    tl.to(selectors.headlineOne, { opacity: 0, ease: "expo.out" });
    tl.to(selectors.cloudTop, { opacity: 0, x: 30, ease: "expo.out" }, "<");
    tl.to(selectors.cloudBottom, { opacity: 0, x: -30, ease: "expo.out" }, "<");
    tl.to(selectors.bgImg, { opacity: 1 }, "<+=0.5");
    tl.to(selectors.headlineTwo, { opacity: 1 }, "<+=0.5");
    tl.to(selectors.bgImg, { opacity: 0, duration: 1.2, delay: 0.5 });
    tl.to(selectors.headlineTwo, { opacity: 0 }, "<");
    tl.to(selectors.headlineTwoGradient, { opacity: 1, duration: 1 }, "<");
    tl.to(selectors.cloudTop, { opacity: 1, x: 0, ease: "expo.out" }, "<+=0.5");
    tl.to(selectors.cloudBottom, { opacity: 1, x: 0, ease: "expo.out" }, "<");
    tl.fromTo(
      selectors.subtitle,
      { y: 10 },
      { opacity: 1, y: 0, duration: 0.5, ease: "back.out(1.7)" },
      "<"
    );

    // Map Pins
    const tlMap = gsap.timeline({
      defaults: { duration: 0.6, ease: "power3.out" },
      scrollTrigger: {
        trigger: selectors.locationsContainer,
        start: "top 50%+=50px",
      },
    });

    tlMap.fromTo(
      selectors.locationPins,
      { y: -5 },
      { y: 0, opacity: 1, stagger: { each: 0.2, from: "random" } }
    );

    const tlPerks = gsap.timeline({
      defaults: { duration: 12 },
      repeat: -1,
    });

    tlPerks.fromTo(
      selectors.perks,
      { y: "700" },
      {
        y: "-1100",
        stagger: { each: 1.2, from: "random", repeat: -1 },
      }
    );
  }
});
