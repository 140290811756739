// Accordionize Yoast Schema FAQ block
const classes = {
  active: 'is-active',
};

document.addEventListener( 'DOMContentLoaded', () => {
  const faqBlocks = $( '.schema-faq' );

  for ( let i = 0; i < faqBlocks.length; i++ ) {
    const faqBlock = faqBlocks[ i ];
    const sections = $( faqBlock ).find( '.schema-faq-section' );
    const container = document.createElement( 'div' );

    container.className = 'faq-left';

    for ( let k = 0; k < sections.length; k++ ) {
      container.appendChild( sections[ k ] );
    }

    faqBlock.innerHTML = container.outerHTML;
  }

  const accordionHeader = $( '.schema-faq-question' );
  const accordionContent = $( '.schema-faq-answer' );

  $( accordionContent ).hide();
  $( accordionHeader ).on( 'click', function () {
    if ( $( this ).hasClass( classes.active ) ) {
      $( this ).next( accordionContent ).slideUp();
      $( this ).removeClass( classes.active );
    }
    else {
      // Close other content
      $( accordionHeader ).not( this ).next( accordionContent ).slideUp( 'fast' );
      $( accordionHeader ).not( this ).removeClass( classes.active );
      $( this ).next( accordionContent ).slideDown( 'fast' );
      $( this ).addClass( classes.active );
    }
  } );
} );
