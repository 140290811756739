document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".template-lp-seo")) {
    $(".fetures-carousel").slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-left w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-left text-blue-navy group-hover:text-white'></span><span class='sr-only'>Prev</span></button>",
      nextArrow:
        "<button class='careers-carousel-button absolute carousel-arrow-center-right w-8 h-8 rounded-full -bottom-24 border border-blue-navy flex justify-center items-center cursor-pointer transition duration-150 group hover:bg-blue-navy'><span class='fas fa-chevron-right text-blue-navy group-hover:text-white'></span><span class='sr-only'>Next</span></button>",
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
});
