import lottie from "lottie-web";

const selectors = {
  lottieContainers: "[data-lottie]",
};

document.addEventListener("DOMContentLoaded", () => {
  if (
    $("body.page-template-template-home") ||
    $("body.page-template-template-home") ||
    $("body.template-lp-seo")
  ) {
    const lottieContainers = document.querySelectorAll(
      selectors.lottieContainers
    );

    if (lottieContainers) {
      lottieContainers.forEach((item) => {
        lottie.loadAnimation({
          container: item,
          renderer: "svg",
          loop: item.dataset.loop == "true" ? true : false,
          autoplay: true,
          path: item.dataset.path,
          rendererSettings: {
            preserveAspectRatio: "none",
          },
        });
      });
    }
  }
});
