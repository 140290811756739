import smoothscroll from "smoothscroll-polyfill";
import download from "downloadjs";
import { responsiveOffset } from "../helpers";
import MicroModal from "micromodal";
import "./smooth-scroll";

document.addEventListener("DOMContentLoaded", () => {
  /**
   * Micromodal.
   */
  MicroModal.init();

  /**
   * Policy menu toggler.
   */
  const policyTogger = document.querySelector("#policyMenuToggler");
  const policyMenu = document.querySelector("#policyMenu");

  function tooglePolicyMenu() {
    if (this) {
      this.classList.toggle("active");
    }

    policyMenu.classList.toggle("active");
  }

  if (policyTogger && policyMenu) {
    policyTogger.addEventListener("click", tooglePolicyMenu);
  }

  /**
   * Toggle news and mentions.
   */
  const moreNews = document.querySelector("#company-news");

  if (moreNews) {
    const moreNewsBtn = document.querySelector("#company-news-more");

    moreNewsBtn.addEventListener("click", (e) => {
      if (moreNews.classList.contains("expand")) {
        const targetEl = $("#company-news");

        if (targetEl.length) {
          $("html, body").animate(
            {
              scrollTop: targetEl.offset().top - 180,
            },
            300
          );
        }
      }

      moreNews.classList.toggle("expand");

      e.preventDefault();
    });
  }

  /**
   * Download file
   */
  const downloadButton = document.querySelectorAll(".cs-download");

  if (downloadButton.length) {
    downloadButton.forEach((el) => {
      const fileUrl = el.getAttribute("data-file");
      console.log("🚀 ~ fileUrl", fileUrl);
      el.addEventListener("click", (e) => {
        e.preventDefault();
        download(fileUrl);
      });
    });
  }

  /**
   * Scrolls on load
   */
  $(window).load(function () {
    if (location.hash) {
      $("html, body").animate(
        { scrollTop: $(location.hash).offset().top - responsiveOffset() },
        300
      );
    }
  });

  /**
   * Scrolls on click (menu)
   */
  $(".navMain__link, .mobile-nav__item a").on("click", function (e) {
    const target = $.attr(this, "href");
    if (target.indexOf("#") === -1) return;

    if (target) {
      let targetEl = target.split("#").pop();
      targetEl = $("#" + targetEl);
      const extraOffset = responsiveOffset();

      if (targetEl.length) {
        $("html, body").animate(
          {
            scrollTop: targetEl.offset().top - extraOffset,
          },
          300
        );

        e.preventDefault();
      } else {
        location.href = target;
      }
    }
  });

  /**
   * Lifecycle Mobile
   */
  const lifecycleMobile = jQuery("#lifecycle-mobile");

  if (lifecycleMobile) {
    lifecycleMobile.slick({
      dots: true,
      slidesToShow: 1,
      autoplay: false,
      fade: true,
      arrows: false,
      touchThreshold: 30,
    });
  }

  /**
   * Scroll to view
   */

  smoothscroll.polyfill();
  const scrollDown = document.querySelector("#scrolldown");
  if (scrollDown) {
    scrollDown.addEventListener("click", () => {
      document.querySelector("#scroll-destination").scrollIntoView({
        behavior: "smooth",
      });
    });
  }

  const scrollToFeatures = document.querySelector("#scrollToFeatures");
  if (scrollToFeatures) {
    scrollToFeatures.addEventListener("click", () => {
      document.querySelector("#featuresSection").scrollIntoView({
        behavior: "smooth",
      });
    });
  }

  /**
   * Stampli banner
   */
  const btnBanner = $(".s-banner__close");

  if (btnBanner.length) {
    setTimeout(() => $(".s-banner").slideToggle("fast"), 2500);
    btnBanner.click(() => $(".s-banner").slideToggle("fast"));
  }
});
