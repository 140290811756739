import axios from "axios";

class Search {
  constructor(target) {
    this.target = target;
    this.searchBox = document.querySelector(this.target);
    this.searchIcon = document.querySelector(
      `${this.target} .search__icon--search`
    );
    this.searchField = document.querySelector(`${this.target} .search__input`);
    this.clearIcon = document.querySelector(
      `${this.target} .search__icon--clear`
    );
    this.container = document.querySelector(".erp-cards");
    this.isSpinnerVisible = false;
    this.isClearVisible = false;
    this.isSearchOn = false;
    this.maxPosts = 30;
    this.IconSearch = "<i class='far fa-search'></i>";
    this.IconSpiner = "<i class='far fa-spinner-third fa-spin'></i>";
    this.previousValue;
    this.typingTimer;
    this.ajaxURL = loadmore_parms.ajax_url; // eslint-disable-line no-undef
    this.events();
  }

  events() {
    this.searchField.addEventListener("keyup", this.typingLogic.bind(this));
    window.addEventListener("keyup", this.closeSearchEsc.bind(this));
    this.clearIcon.addEventListener("click", this.clear.bind(this));
  }

  closeSearchEsc(e) {
    if (e.keyCode == 27) {
      if (this.isSearchOn) {
        this.clear();
      }
    }
  }

  typingLogic() {
    if (this.searchField.value != this.previousValue) {
      clearTimeout(this.typingTimer);

      if (this.searchField.value) {
        if (!this.isSpinnerVisible) {
          this.searchIcon.innerHTML = this.IconSpiner;
          this.isSpinnerVisible = true;
        }
        this.typingTimer = setTimeout(this.getPosts.bind(this), 300);
      } else {
        this.searchIcon.innerHTML = this.IconSearch;
        this.isSpinnerVisible = false;
      }

      if (!this.isClearVisible) {
        this.clearIcon.classList.add("block");
        this.isClearVisible = true;
      }

      if (this.isClearVisible && this.searchField.value === "") {
        this.getPosts();
        this.clearIcon.classList.remove("block");
        this.isClearVisible = false;
      }
    }
    this.previousValue = this.searchField.value;
  }

  getData() {
    let form = new FormData();
    form.append("action", "erp_search");
    form.append("query", this.searchField.value);

    return form;
  }

  getPosts() {
    const data = this.getData();

    return new Promise((resolve, reject) =>
      axios
        .post(this.ajaxURL, data)
        .then((res) => {
          if (200 === res.status) {
            this.container.innerHTML = res.data;
            resolve(true);
          } else {
            reject(new Error(res.status));
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.searchIcon.innerHTML = this.IconSearch;
          this.isSpinnerVisible = false;
        })
    );
  }

  clear() {
    this.isClearVisible = false;
    this.searchField.value = "";
    this.searchField.focus();
    this.clearIcon.classList.remove("block");
    this.getPosts();
  }
}

// Init.
document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("#search-erps")) {
    new Search("#search-erps");
  }
});
