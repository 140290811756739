document.addEventListener('DOMContentLoaded', () => {
  /**
   * Lifecycle Desktop
   */
  let t;
  let hasInit = false;

  const Steps = $('#lifecycle_desktop ._step'),
    Dots = $('#lifecycle_desktop ._dot');

  $.each(Steps, function (i) {
    const that = $(this),
      text = $(`#text_${i + 1}`);

    that.mouseenter(function () {
      if (hasInit) {
        $('._step, ._text').removeClass('active');

        that.addClass('active');
        text.addClass('active');
      }

      stopStepCar();
    });

    that.mouseleave(startStepCar);
  });

  $.each(Dots, function (i) {
    const that = $(this),
      step = $(`#step_${i + 1}`),
      text = $(`#text_${i + 1}`);

    that.hover(
      function () {
        stopStepCar();

        if (hasInit) {
          $('._step, ._title, ._text').removeClass('active');

          step.addClass('active');
          text.addClass('active');
        }
      },
      function () {
        startStepCar();
      }
    );
  });

  function stepCarousel() {
    if (hasInit) {
      const step = $('._step.active'),
        id = step.attr('id'),
        index = Number(id.substr(id.length - 1)),
        text = $(`#text_${index}`),
        textNext = $(`#text_${index + 1}`),
        next = step.next();

      text.removeClass('active');
      textNext.addClass('active');

      step.removeClass('active');
      next.addClass('active');

      if (index == Steps.length) {
        $('#step_1, #text_1').addClass('active');
      }
    }
  }

  function startStepCar() {
    if (hasInit) t = setInterval(stepCarousel, 2500);
  }

  function stopStepCar() {
    if (hasInit) clearInterval(t);
  }

  // init
  let Lifecycle = jQuery('#lifecycle_desktop');

  if (Lifecycle.length) {
    let positionLifecycle = Lifecycle.offset().top;
    let windowHeight = jQuery(window).height();
    let animationStart = false;

    jQuery(window).scroll(function () {
      Lifecycle = jQuery('#lifecycle_desktop');
      positionLifecycle = Lifecycle.offset().top;
      windowHeight = jQuery(window).height();
      const offsetTop = jQuery(this).scrollTop();

      if (offsetTop + windowHeight > positionLifecycle + windowHeight / 2) {
        if (!animationStart) {
          jQuery('#init_state').addClass('initiate');
          hasInit = true;
          animationStart = true;
          jQuery('#step_1, #text_1').addClass('active');
          startStepCar();
        }
      }
    });
  }
});
