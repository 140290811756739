import { enableBodyScroll } from 'body-scroll-lock';

document.addEventListener('DOMContentLoaded', () => {
  // Nav Mobile
  const mobileTrigger = $('#mobile-toggler');
  const mobileMenu = $('.nav-mobile-wrap');
  const closeMenu = $('.close-mobile');
  const mobileNav = document.querySelector('#mobile-main');

  mobileTrigger.on('click', function (e) {
    const $this = $(this);
    const wrapHeight = `${window.innerHeight - 95}px`;

    mobileMenu.css('height', wrapHeight);
    $this.toggleClass('is-active');
    mobileMenu.toggleClass('nav-mobile-wrap--open');
    $('html').toggleClass('overflow-hidden');
    e.preventDefault();
  });

  closeMenu.on('click', (e) => {
    mobileMenu.removeClass('nav-mobile-wrap--open');
    enableBodyScroll(mobileNav);
    e.preventDefault();
  });

  /**
   * Handle mobile submenus
   */
  $('.mobile-nav__parent-item').click(function () {
    const $this = $(this);
    const childWrapper = $this.next();

    $this.toggleClass('active');
    childWrapper.slideToggle();
  });

  /**
   * Select the right menu level
   */

  const catLinks = $('.mobile-nav__item a');

  function openLevel() {
    const item = $(this).parent();

    if (item.hasClass('has-children')) item.next().addClass('active');

    if (item.hasClass('has-children-inner'))
      item.children().last().addClass('active');

    if (item.hasClass('mobile-nav__item--back'))
      item.parent().parent().removeClass('active');
  }

  for (let i = 0; i < catLinks.length; i++) {
    catLinks[i].addEventListener('click', openLevel);
  }
});
