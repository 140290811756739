import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import {
  faArrowToBottom,
  faSpinnerThird,
  faSearch,
  faBars,
  faSlidersH,
  faTimes as farTimes,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTimes,
  faCaretRight,
  faCaretDown,
  faPlus,
  faArrowRight,
  faCircle,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faLongArrowAltRight,
  faKey,
  faSignOut,
  faSyncAlt,
} from "@fortawesome/pro-solid-svg-icons";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

// add the imported icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTimes,
  faCaretRight,
  faCaretDown,
  faPlus,
  faArrowRight,
  faCircle,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faSearch,
  faArrowToBottom,
  faSpinnerThird,
  faBars,
  faSlidersH,
  farTimes,
  faLongArrowAltRight,
  faKey,
  faSignOut,
  faSyncAlt
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
