import Isotope from 'isotope-layout';
import { getUrlParameter } from '../helpers';

document.addEventListener('DOMContentLoaded', () => {
  /**
   * Filter Resources.
   */
  if ($('#isotope-list').length) {
    let filterData = [];
    const elem = document.querySelector('#isotope-list'),
      rFilters = document.querySelector('#filters'),
      filterOpts = rFilters.querySelectorAll('a');

    // Get all filters
    filterOpts.forEach((el) => filterData.push(el.dataset.filter));

    // Check whether the url parameter matches any of the filters
    const preSelect = filterData.includes(`.${getUrlParameter('filter')}`)
      ? `.${getUrlParameter('filter')}`
      : false;

    setTimeout(() => {
      const iso = new Isotope(elem, {
        itemSelector: '.item',
        layoutMode: 'fitRows',
        filter: preSelect ? preSelect : '*',
      });

      function filterResources(e) {
        e.preventDefault();

        // don't proceed if already selected
        if (this.classList.contains('selected')) {
          return false;
        }

        //When an item is clicked, sort the items.
        const selector = this.getAttribute('data-filter');
        iso.arrange({ filter: selector });

        rFilters.querySelector('.selected').classList.remove('selected');
        this.classList.add('selected');

        e.preventDefault();
        return false;
      }

      filterOpts.forEach((el) => el.addEventListener('click', filterResources));
    }, 500);
  }

  /**
   * Resource Modal.
   */
  $('.btn-modal-resource').click(function () {
    const $this = $(this),
      modalSpinner = $this.find('.modal-spinner-default'),
      modalLabel = $this.find('.modal-label'),
      btnWidth = $this.innerWidth(),
      id = $this.data('id'),
      title = $this.data('title'),
      resource = $this.data('resource'),
      remote = $this.data('remote'),
      form = $this.data('form');

    $.ajax({
      url: loadmore_parms.ajax_url, // eslint-disable-line no-undef
      type: 'POST',
      data: {
        title: title,
        form: form,
        resource: resource,
        remote: remote,
        id: id,
        action: 'load_resource',
      },

      beforeSend: function () {
        $this.css({ width: btnWidth });
        modalLabel.hide();
        modalSpinner.show();
      },

      success: function (newHTML) {
        $(newHTML).appendTo('body').modal({
          fadeDuration: 100,
          fadeDelay: 1,
        });
      },

      error: function (jqXHR, textStatus, err) {
        console.log(`Error: ${err}`);
      },

      complete: function () {
        modalSpinner.hide();
        modalLabel.show();
        $this.css({ width: 'auto' });
      },
    });

    return false;
  });
});
