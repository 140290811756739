document.addEventListener('DOMContentLoaded', () => {
  /**
   * Modal button.
   */
  $('.btn-modal-default').click(function (e) {
    e.preventDefault();

    const $this = jQuery(this),
      modalSpinner = $this.find('.modal-spinner-default'),
      modalLabel = $this.find('.modal-label'),
      btn = this.getBoundingClientRect(),
      title = $this.data('title'),
      form = $this.data('form');

    let btnWidth;

    $.ajax({
      url: loadmore_parms.ajax_url, // eslint-disable-line no-undef
      type: 'POST',
      data: {
        title: title,
        form: form,
        action: 'load_modal',
      },

      beforeSend: function () {
        if (btn.width) {
          btnWidth = btn.width;
        } else {
          btnWidth = btn.right - btn.left;
        }

        $this.css({ width: btnWidth });
        modalLabel.hide();
        modalSpinner.show();
      },

      success: function (newHTML) {
        $(newHTML).appendTo('body').modal({
          fadeDuration: 100,
          fadeDelay: 1,
        });
      },

      error: function (jqXHR, textStatus, err) {
        console.log(`Error: ${err}`);
      },

      complete: function () {
        modalSpinner.hide();
        modalLabel.show();
        $this.css({ width: 'auto' });
      },
    });

    return false;
  });
});
