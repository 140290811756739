export const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const isMobile = () => {
  if (window.innerWidth < 992) {
    return true;
  }

  return false;
};

export const responsiveOffset = () => (isMobile() ? 100 : 220);
